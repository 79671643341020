#search-bar-0.form-control {
	margin-bottom: 5px;
}

#cetak-surat > table {
	page-break-inside: auto;
}

#cetak-surat > table > tr {
	page-break-inside: avoid;
	page-break-after: auto;
}

#cetak-surat > table > tr > thead {
	display: table-header-group;
}

#cetak-surat > table > tr > tfoot {
	display: table-header-group;
}
